// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../components/SEO'
import LearningCategoriesContainer from '../../../containers/LearningCategoriesContainer'
import LayoutMain from '../../../layout'

const Profile: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Información General" />
      <WithPrivateRoute component={LearningCategoriesContainer} />
    </LayoutMain>
  )
}

export default Profile
